<template>
  <div class="createForm">
    <div class="filterOptions">
      <div class="firstOption">
        <vs-input id="searchInput" v-model="filters.searchValue" placeholder="Nombre del plan..." />
      </div>
      <div class="chipsOptions" v-if="showFilters">
        <vs-button color="#b32936" size="mini">
          Deporte
        </vs-button>
        <vs-button color="#b32936" size="mini">
          Ocio
        </vs-button>
        <vs-button color="#b32936" size="mini">
          Cultura
        </vs-button>
        <vs-button color="#b32936" size="mini">
          Gastronomia
        </vs-button>
        <vs-button color="#b32936" size="mini">
          Quedadas
        </vs-button>
        <vs-button transparent color="#fff" size="mini">
          <i class="fas fa-chevron-right"></i>
        </vs-button>
      </div>
      <div v-if="showFilters" class="restOptions">
        <vs-col :w="7">
          <vs-row>
            <v-date-picker
              class="datePicker"
              v-model="dates"
              mode="range"
              color="pink"
              :value="{ start: new Date(), end: new Date() }"
              is-range
              is-dark
              hint="Required"
              persistent-hint
              :available-dates="{ start: new Date(), end: null }"
              :attributes="calendarAttributes"
              :min-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div class="calendarInput">
                  <svg
                    class="calIcon text-gray-600 w-4 h-full mx-2 absolute pointer-events-none"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                  </svg>
                  <vs-input
                    class="bg-white border px-2 py-1 rounded calInput"
                    label-placeholder="Cuando es tu evento?"
                    :value="!inputValue.start ? '' : inputValue.start + '-' + inputValue.end"
                    v-on="inputEvents.start"
                  />
                </div>
              </template>
            </v-date-picker>
          </vs-row>
          <vs-row>
            <vs-col :w="3">
              <vs-button transparent color="#fff">
                €
              </vs-button>
            </vs-col>
            <vs-col :w="9" class="slider">
              <vue-slider
                v-model="filters.priceRange"
                :tooltip-formatter="'{value}€'"
                :enable-cross="false"
                :min="0"
                :max="1000"
                width="80%"
                style="margin:auto"
              />
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col :w="5">
          <RoundSlider
            class="kmSlider"
            style="margin: auto;"
            tooltipColor="#fff"
            radius="30"
            width="5"
            v-model="filters.geoPosition"
            :tooltipFormat="formatTooltip"
            :change="onChangeKm"
          />
        </vs-col>
      </div>
    </div>
    <EventsSorterBG @onSort="onSort" :sortBy="sortBy" />
    <CardGrid v-bind:cards="filteredEvents(filters)" class="cardGrid" />
  </div>
</template>

<script>
import CardGrid from "../components/CardGrid.vue";
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
import EventsFilterSB from "@/components/SIDEBARS/EventsFilterSB.vue";
import EventsSorterBG from "@/components/EventsSorterBG.vue";
import { loadingMix, noficationsDialog, authMix, eventMix } from "@/common/mixin.js";

import VueSlider from "vue-slider-component";
import RoundSlider from "vue-round-slider";
import "vue-slider-component/theme/default.css";

export default {
  name: "EventList",
  mixins: [loadingMix, noficationsDialog, authMix, eventMix],
  components: {
    CardGrid,
    PacmanLoader,
    EventsFilterSB,
    EventsSorterBG,
    VueSlider,
    RoundSlider
  },
  computed: {},
  data() {
    return {
      events: [],
      sortBy: -1,
      showFilters: true,
      coords: null,
      filters: {
        isPrivate: false,
        searchValue: "",
        priceRange: [0, 1000],
        geoPosition: 0
      },
      dates: [],
      calendarAttributes: [
        {
          dot: "green",
          dates: [
            new Date(),
            new Date().setDate(new Date().getDate() + 3),
            new Date().setDate(new Date().getDate() + 6)
          ],
          popover: {
            label: "Festa Major Martorell"
          },
          customData: {
            isComplete: false,
            dates: { weekdays: 6 },
            color: "red"
          }
        }
      ]
    };
  },
  created() {
    this.getEventsList();
  },
  watch: {},
  methods: {
    async getEventsList() {
      this.events = await this.getEvents();
      this.onFilter({ private: false });
    },

    onChangeKm(a) {
      this.filters.geoPosition = a.value;
    },

    formatTooltip(a) {
      return `${a.value} Km`;
    },

    calculateDistance(event) {
      if (this.coords) {
        const { latitude, longitude } = this.coords;
        const { lat, lng } = event.location.latlng;
        let rad = function(x) {
          return (x * Math.PI) / 180;
        };
        var radioEarth = 6378.137; // km
        var dLat = rad(lat - latitude);
        var dLong = rad(lng - longitude);
        var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(rad(latitude)) * Math.cos(rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = radioEarth * c;
        return distance;
      } else {
        return null;
      }
    },

    onFilter(filter) {
      this.filters = { ...this.filters, ...filter };
    },

    filteredEvents(filter) {
      let aFilteredItems = [];

      if (this.events.length > 0) {
        if (!filter) {
          aFilteredItems = [...this.events];
        } else {
          const filtersKeys = Object.keys(filter);
          filtersKeys.forEach((key, ind) => {
            let eventsToFilter = [...this.events];
            if (ind !== 0) {
              eventsToFilter = aFilteredItems;
            }

            aFilteredItems = eventsToFilter.filter(event => {
              switch (key) {
                case "isPrivate":
                  return event[key] === filter[key];
                  break;
                case "searchValue":
                  if (filter[key] !== "") {
                    return (
                      event.title.toLocaleLowerCase() === filter[key].toLocaleLowerCase() ||
                      event.title.toLocaleLowerCase().includes(filter[key].toLocaleLowerCase())
                    );
                  } else {
                    return event;
                  }
                  break;
                case "priceRange":
                  return event.price >= filter[key][0] && event.price <= filter[key][1];
                  break;
                case "geoPosition":
                  if (event.location && filter[key] > 0) {
                    const distance = this.calculateDistance(event);
                    if (filter[key] > distance) {
                      return event;
                    }
                  } else {
                    return event;
                  }
                  break;
                default:
                  return event;
                  break;
              }
            });
          });
          aFilteredItems = aFilteredItems;
        }
        return this.sortBy >= 0 ? this.sorteredEvents(aFilteredItems) : aFilteredItems;
      } else {
        return [];
      }
    },

    onSort(type) {
      this.sortBy = type;
    },

    sorteredEvents(aEvents) {
      console.log(1);
      return aEvents.sort((a, b) => {
        switch (this.sortBy) {
          case 0:
            return a.title.localeCompare(b.title);
            break;
          case 2:
            const distA = this.calculateDistance(a).toString();
            const distB = this.calculateDistance(b).toString();
            return distA.localeCompare(distB);
          case 4:
            return a.startDate.localeCompare(b.startDate);
          case 5:
            return a.price.toString().localeCompare(b.price.toString());
          default:
            break;
        }
      });
    }
  },
  mounted() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        currentPosition => {
          this.coords = currentPosition.coords;
        },
        e => {
          console.log(e);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    }
  }
};
</script>

<style scoped>
.filterOptions {
  padding-top: 0rem;
  background: linear-gradient(180deg, #f83255, #521016);
  /* border-radius: 0rem 0rem 0.25rem 0.25rem; */
  border-radius: 0rem 0rem 1rem 1rem;
}

.firstOption,
.chipsOptions,
.restOptions {
  padding: 0 1rem;
  display: flex;
  justify-content: space-evenly;
}

.chipsOptions,
.restOptions {
  padding-top: 0rem;
  justify-content: space-around;
}

.slider {
  margin: auto;
}

.cardLabel {
  width: 60%;
  background: #ffd000;
  border-radius: 0 10rem;
}

.joinBtn {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.joinBtn > button {
  width: 95%;
}

.header {
  margin: 2rem;
  margin-bottom: 2rem;
}

.cardGrid {
  margin: 1rem;
}

.spinner {
  margin-top: 150px;
  display: flex;
  justify-content: center;
}

.createForm {
  margin-bottom: 2rem;
}

.calendarInput {
  display: flex;
}

.calendarInput > svg {
  width: 1.75rem;
  color: var(--main-bg);
}

.calInput {
  padding: 0 !important;
  border-radius: 1rem !important;
  width: 100%;
}

/* // Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */
@media (max-width: 575px) {
  .header {
    margin: 2rem;
    margin-bottom: 2rem;
  }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .header {
    margin: 5rem;
    margin-bottom: 3rem;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
</style>

<style>
.calInput > div > input {
  padding: 0;
  width: 100%;
}

#searchInput {
  position: absolute;
  top: 1rem;
}

#searchInput > div > .vs-input {
  padding: 0 1rem;
}

.kmSlider > .rs-container > .rs-tooltip-text {
  font-size: small !important;
}
</style>
