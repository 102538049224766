<template>
  <!-- <vs-button-group danger> -->
  <div
    style="display: flex;
    justify-content: space-evenly;
    border-radius: 0 0 1rem 1rem;
    box-shadow: 0px 8px 10px 0px rgb(0 0 0 / 60%);"
  >
    <vs-button
      border
      style="color: #521016; border-radius:1rem; min-width: 3rem;"
      flat
      color="#b32936"
      size="mini"
      icon
      :active="sortBy === 0"
      @click="onSort(0)"
    >
      <!-- <i class="fas fa-award"></i>  -->
      &nbsp; Recomendados
    </vs-button>
    <vs-button
      border
      style="color: #521016; border-radius:1rem; min-width: 3rem;"
      flat
      color="#b32936"
      size="mini"
      icon
      :active="sortBy === 1"
      @click="onSort(1)"
    >
      <!-- <i class="fas fa-trophy"></i> -->
      &nbsp; Populares
    </vs-button>
    <vs-button
      border
      style="color: #521016; border-radius:1rem; min-width: 3rem;"
      flat
      color="#b32936"
      size="mini"
      icon
      :active="sortBy === 2"
      @click="onSort(2)"
    >
      <!-- <i class="fas fa-city"></i> -->
      &nbsp; Km
    </vs-button>
    <vs-button
      border
      style="color: #521016; border-radius:1rem; min-width: 3rem;"
      flat
      color="#b32936"
      size="mini"
      icon
      :active="sortBy === 4"
      @click="onSort(4)"
    >
      <!-- <i class="fas fa-clock"></i> -->
      &nbsp; Fecha
    </vs-button>
    <vs-button
      border
      style="color: #521016; border-radius:1rem; min-width: 3rem;"
      flat
      color="#b32936"
      size="mini"
      icon
      :active="sortBy === 5"
      @click="onSort(5)"
    >
      <!-- <i class="fas fa-coins"></i> -->
      &nbsp; Coste
    </vs-button>
  </div>

  <!-- </vs-button-group> -->
</template>

<script>
export default {
  name: "EventsSorterBG",
  props:["sortBy"],
  methods: {
    onSort(type) {
      this.$emit("onSort", type);
    }
  },
  data: () => {
    return {};
  }
};
</script>

<style scoped></style>
