<template>
  <div>
    <vs-sidebar
      class="sbMenu"
      :open="!this.$store.state.showChatSB"
      hover-expand
      reduce
      background="#f8f9fa"
      style="max-width: 300px"
      ref="EventsFilterSB"
    >
      <template #logo>
        <i class="fas fa-filter"></i>
        <h4>Filters</h4>
      </template>

      <vs-sidebar-item>
        <template #icon> <i class="fa fa-lock"></i></template>
        <vs-switch danger v-model="isPrivate" @change="onFilter({ private: isPrivate })">
          <template #off> <i class="fa fa-unlock"></i> Public </template>
          <template #on> Private<i class="fa fa-lock"></i> </template>
        </vs-switch>
      </vs-sidebar-item>

      <vs-sidebar-item>
        <template #icon> <i class="fas fa-dice-one"></i></template>

        <vs-input
          block
          placeholder="Nombre del plan"
          :color="title !== '' ? 'success' : '#fff'"
          :label="isPrivate ? 'Nombre del plan : ' : 'Title : '"
          v-model="title"
          @change="onFilter({ title })"
        >
        </vs-input>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <template #icon> <i class="fas fa-dice-two"></i></template>
        <vs-select label="Temática" multiple filter placeholder="Temática" v-model="tematica">
          <vs-option-group v-for="(tema, ind) in tematicas" :key="'tematica_' + tema + ind">
            <div slot="title">
              {{ tema.title }}
            </div>
            <vs-option
              v-for="(subtema, ind) in tema.subtema"
              :key="'subtema_' + ind"
              :label="subtema"
              :value="subtema"
            >
              {{ subtema }}
            </vs-option>
          </vs-option-group>
        </vs-select>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <template #icon> <i class="fas fa-dice-three"></i></template>
        <places
          style="position:static"
          v-model="form.country.label"
          placeholder="Donde quedamos ?"
          @change="
            val => {
              form.country.data = val;
            }
          "
          :options="options"
        ></places>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <template #icon> <i class="fas fa-dice-four"></i></template>
        <v-date-picker
          mode="range"
          tint-color="#f142f4"
          color="pink"
          :landscape="true"
          :attributes="calendarAttributes"
          :available-dates="{ start: new Date(), end: null }"
          is-dark
          value=""
        ></v-date-picker>
      </vs-sidebar-item>

      <vs-sidebar-item>
        <template #icon> <i class="fas fa-dice-five"></i></template>
        <vs-select
          label="Franja horaria"
          multiple
          placeholder="Escoge tu franja horaria"
          v-model="franja"
          @change="onFilter({ Franja: franja })"
        >
          <vs-option label="Mañana" value="1">
            Mañana
          </vs-option>
          <vs-option label="Tarde" value="2">
            Tarde
          </vs-option>
          <vs-option label="Noche" value="4">
            Noche
          </vs-option>
        </vs-select>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <template #icon><i class="fas fa-euro-sign"></i></template>
        <vs-switch class="inputs" warn v-model="free" @change="onFilter({ free })">
          <template #off> Gratis</template>
          <template #on> De pago <i class="fa fa-money" aria-hidden="true"></i> </template>
        </vs-switch>
      </vs-sidebar-item>
      <vs-sidebar-item>
        <vs-input
          v-if="free"
          type="text"
          placeholder="€"
          :color="price !== '' ? 'success' : '#fff'"
          label="Precio : "
          v-model="price"
          @change="onFilter({ price })"
        >
        </vs-input>
        <vue-slider
          v-model="PriceRange"
          :tooltip-formatter="'{value}€'"
          :enable-cross="false"
          :min="0"
          :max="1000"
          width="80%"
          style="margin:auto"
        ></vue-slider>
      </vs-sidebar-item>

      <vs-sidebar-item>
        <template #icon><i class="fas fa-search"></i></template>
        <vs-button block success @click="onSearch">
          <i class="fas fa-search fa-2x"></i> Buscar
        </vs-button>
      </vs-sidebar-item>

      <template #footer>
        <vs-button block @click="onClear"> <i class="fas fa-sync-alt"></i> Clear </vs-button>
      </template>
    </vs-sidebar>

    <vs-dialog prevent-close blur v-model="isPrivate">
      <template #header>
        <h4 class="not-margin">Planes <b>Privados</b></h4>
      </template>

      <div class="con-form">
        <vs-select
          filter
          label-placeholder="Nombre del evento"
          v-model="selectedEvent"
          class="inputs"
        >
          <vs-option
            v-for="(event, i) in filterEvents(events)"
            :key="i"
            :label="event.title"
            :value="event.id"
          >
            <vs-avatar :size="'30'" square class="creator-avatar">
              <img
                loading="lazy"
                :src="event.image || 'https://picsum.photos/400/400/?image=20'"
                alt="avatar"
              />
            </vs-avatar>
            <p
              style="margin: 0rem 1rem;
    text-transform: capitalize;"
            >
              {{ event.title }} by
              <!-- {{ event.CreatedBy }} -->
            </p>
            <Creator
              :creator="event.created_by"
              :test="getCreator(event)"
              :profileImg="event.CreatorImg || 'https://picsum.photos/400/400/?image=20'"
              :mini="true"
            />
          </vs-option>
        </vs-select>
        <!-- <vs-input v-model="selectedEvent" placeholder="Código del evento"> </vs-input> -->
        <vs-input
          type="password"
          v-model="eventPass"
          label-placeholder="Contraseña del evento"
          class="inputs"
        >
        </vs-input>
      </div>

      <template #footer>
        <div class="footer-dialog">
          <vs-button block @click="onSearchPrivate">
            Buscar
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import { imageMix } from "@/common/mixin.js";
import Creator from "@/components/Creator.vue";
import Places from "vue-places";
import axios from "axios";
import format from "date-fns/format";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "eventsFilterSB",
  mixins: [imageMix],
  components: {
    Places,
    VueSlider,
    Creator
  },
  props: ["events"],
  computed: {},
  methods: {
    async onSearchPrivate() {
      const body = { params: { Event_id: this.selectedEvent, Pass: this.eventPass } };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .get("/events", body)
        .then(response => {
          if (response.data.success) {
            this.$store.commit("setEvent", {
              ...response.data.events[0],
              assistants: this.getAssistants(),
              private: true
            });
            this.$router.push({ name: "Event" });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    onFilter(filter) {
      this.$emit("onFilter", filter);
    },
    onSearch() {},
    onClear() {
      this.franja = ["1", "2", "4"];
      this.Title = "";
      this.Tematica = "";
      this.Private = false;
      this.Free = false;
      this.Price = "";
    },
    async getCreator(event) {
      if (event && event.CreatedBy) {
        const apiClient = axios.create({
          baseURL: !window.location.host.includes("localhost")
            ? "https://api.bmyplan.com"
            : "http://localhost:8087" //
        });

        // let users = events.map(({ CreatedBy }) => CreatedBy);
        // let uniqueUsers = [...new Set(users)];

        const body = { params: { username: event.CreatedBy } };
        await apiClient
          .get("/profile", body)
          .then(response => {
            event.CreatorImg = this.parseImages(response.data);
          })
          .catch(error => {
            console.error(error);
            event.CreatorImg = this.profileImg;
          });
      }
    },

    filterEvents(eventList) {
      return eventList.filter(event => event.participant && event.private);
    },
    async getAssistants() {
      const body = { params: { event_id: this.selectedEvent } };
      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      await apiClient
        .get("/participation", body)
        .then(response => {
          this.parseImages(response.data, true);
          return response.data;
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  watch: {},
  mounted() {
    this.onFilter({ private: false });
  },
  data() {
    return {
      eventsList: this.$props.events,
      profileImg: "https://upload.wikimedia.org/wikipedia/commons/3/33/Mr._Bean_2011.jpg",
      selectedEvent: "",
      eventPass: "",
      title: "",
      tematica: "",
      isPrivate: false,
      free: false,
      price: "",
      PriceRange: [0, 1000],
      range: {
        start: new Date(),
        end: new Date().setDate(new Date().getDate() + 7)
      },
      calendarAttributes: [
        {
          dot: "green",
          dates: [
            new Date(),
            new Date().setDate(new Date().getDate() + 3),
            new Date().setDate(new Date().getDate() + 6)
          ],
          popover: {
            label: "Festa Major Martorell"
          },
          customData: {
            isComplete: false,
            dates: { weekdays: 6 },
            color: "red"
          }
        }
      ],
      franja: ["1", "2", "4"],
      modal2: false,
      modal3: false,
      options: {
        appId: "pl0FNNG5K9X8",
        apiKey: "f4576639c7a669eb121f4600076d813e",
        postcodeSearch: true,
        countries: ["es"]
      },
      form: {
        country: {
          label: "",
          data: {}
        }
      },
      tematicas: [
        {
          title: "ACTIVIDADES DE OCIO",
          subtema: [
            "Ocio",
            "Aniversarios",
            "Excursiones",
            "Fiestas populares",
            "Fiestas para toda la familia",
            "Fiestas para niños",
            "Fiestas para adultos",
            "Parques temáticos y de agua",
            "Quedadas",
            "Streamings"
          ]
        },
        {
          title: "CULTURA",
          subtema: [
            "Cultura",
            "Tours y rutas",
            "Visitas guiadas",
            "Rutas de arte e historia",
            "Conferencias",
            "Videoconferencias o webinar"
          ]
        },
        {
          title: "DEPORTES Y AVENTURAS",
          subtema: [
            "Deportes y aventuras",
            "Acuáticos",
            "Aéreos",
            "Atletismo",
            "Ciclismo",
            "Colectivos",
            "Combinados o múltiples",
            "Con animales",
            "Con raqueta",
            "Cursos deportivos",
            "De agarre/fuerza",
            "De aventura/extremos",
            "De baile",
            "De combate, lucha y relajación",
            "De deslizamiento",
            "De invierno",
            "De mesa/mentales",
            "De montaña",
            "De motor",
            "De precisión",
            "De pelota",
            "De realidad virtual",
            "Ferias de deporte",
            "Otros deportes"
          ]
        },
        {
          title: "GASTRONOMÍA",
          subtema: [
            "Gastronomía",
            "Cursos de cata y coctelería",
            "Catas de vinos y otros",
            "Degustaciones y experiencias gastronómicas",
            "Ferias de comidas",
            "Visitas a bodegas y viñedos"
          ]
        },
        {
          title: "CURSOS",
          subtema: [
            "Cursos",
            "Cursos online",
            "De cocina",
            "De fotografia",
            "De idiomas",
            "De manualidades",
            "De crecimiento personal",
            "De música",
            "De deporte",
            "De gastronomía",
            "De baile",
            "De belleza"
          ]
        },
        {
          title: "BELLEZA",
          subtema: [
            "Adelgazamiento",
            "Cursos de belleza",
            "Manicuras y pedicuras",
            "Peluqiería",
            "Presoterapia",
            "Sesiones de fotos",
            "Tratamientos"
          ]
        },
        {
          title: "BIENESTAR",
          subtema: ["Bienestar", "Masajes", "Spas", "Salud"]
        }
      ]
    };
  }
};
</script>

<style>
.ap-dataset-places {
  background: #212529;
  text-align: start;
}

.ap-suggestion:hover {
  background: #a10673;
}

.ap-suggestion .ap-cursor {
  background: #060f1d;
}
</style>

<style scoped>
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
@media (min-width: 992px) {
  .sbMenu {
    top: 30px;
    max-height: calc(100% - 30px);
  }
}

@media (max-width: 991px) {
  .sbMenu {
    top: 54px;
    max-height: calc(100% - 54px);
  }
}

.inputs {
  margin: 1.5rem;
  max-width: 90%;
}
</style>
